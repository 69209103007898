import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Swiper, SwiperSlide } from 'swiper/react'

import Layout from '../components/layouts'
import FashionStyle from '../components/FashionStyle'

export default function Accessories(props) {
  const hero = props.data.contentfulHero
  const images = props.data.contentfulIdeations.images

  return (
    <Layout>
      <div className="gallery">
        <div className="page-hero relative">
          <GatsbyImage
            image={hero.heroImage.gatsbyImageData}
            className="fadeInUp"
            alt={hero.title}
          />
        </div>
        {props.data.contentfulCollection.groups.map((group) => {
          return (
            <div key={group.id}>
              <div className="gallery__wrapper">
                {group.styles.map((style) => (
                  <FashionStyle key={style.id} data={style} />
                ))}
              </div>
            </div>
          )
        })}
        <div style={{ width: '90vw', maxWidth: '1024px', margin: 'auto' }}>
          <Swiper slidesPerView={1} centeredSlides loop navigation>
            {images.map((image) => (
              <SwiperSlide key={image.id}>
                <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query accessoriesQuery {
    contentfulHero(title: { eq: "Handbag Hero" }) {
      title
      heroImage {
        gatsbyImageData(width: 1400, quality: 80, placeholder: NONE)
      }
    }
    contentfulCollection(collectionName: { eq: "Accessories" }) {
      collectionName
      groups {
        id
        groupName
        styles {
          id
          title
          sketchItems {
            id
            title
            description
            image {
              title
              fixed(width: 600) {
                src
              }
            }
            onHoverImage {
              title
              fixed(width: 600) {
                src
              }
            }
          }
          additionalItems {
            id
            description
            image {
              title
              fixed(width: 600) {
                src
              }
            }
            material {
              title
              image {
                fixed(quality: 20) {
                  src
                }
              }
            }
            onHoverImage {
              title
              fixed(width: 600) {
                src
              }
            }
          }
        }
      }
    }
    contentfulIdeations(title: { eq: "Accessories Ideation" }) {
      images {
        id
        title
        gatsbyImageData(quality: 80, placeholder: NONE)
      }
    }
  }
`
